<template>
  <div id="abnormal-base">
    <div class="health">
      <app-tree @select="onSelect"></app-tree>
      <div class="content">
        <!-- <h3>{{$store.state.selectedData.name}}-{{$store.state.parentData.name}}</h3> -->
        <h3>
          <span v-if="parentTitle">{{ parentTitle }}-</span
          ><span v-if="title">{{ title }}</span>
        </h3>

        <div class="search-shell">
          <p class="title">基站数据异常列表</p>
        </div>
        <div class="search-btn">
          <div class="select-all">
            <div class="label">企业或基站名称</div>
            <a-input
              @change="onChangeVal"
              :value="JZMC"
              style="width: 170px"
              placeholder="请输入企业或基站名称"
            />
          </div>
          <a-button @click="onSearch" class="abnormal-btn" type="primary">
            查询
          </a-button>
          <a-button class="abnormal-btn" @click="onExport" type="primary">
            导出
          </a-button>
        </div>
        <div class="table-shell">
          <a-table
            :customRow="customRow"
            :loading="loading"
            :row-key="(record) => record.station_bh"
            :scroll="{ y: 490 }"
            :columns="columns"
            size="small"
            :data-source="data"
            bordered
            :pagination="false"
          >
          </a-table>
          <div style="display: flex; padding-bottom: 10px">
            <div style="flex: 1"></div>
            <a-pagination
              show-quick-jumper
              :page-size="pageSize"
              v-model="current"
              :total="total"
              :show-total="
                (total, range) =>
                  `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`
              "
              @change="onChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTree from "@/components/AppTree.vue";
import BaseSelect from "../../components/BaseSelect.vue";
import { http } from "../../utils/Http.js";

const columns = [
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    width: 250,
    ellipsis: true,
    // align: 'center',
  },
  {
    title: "基站名称",
    width: 180,
    dataIndex: "station_name",
    align: "center",
  },
  // {
  // 	title: '固定值',
  // 	dataIndex: 'GDZ',
  // 	align: 'center',
  // },
  {
    title: "异常",
    dataIndex: "Abnormal_num",
    align: "center",
  },
  {
    title: "超标",
    dataIndex: "Overproof_num",
    align: "center",
  },
  {
    title: "预警",
    dataIndex: "Early_num",
    align: "center",
  },
  {
    title: "合计",
    dataIndex: "TOTAL",
    align: "center",
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      current: 1,
      width: "120px",
      pageSize: 20,
      total: 0,
      JZMC: "",
      EnterpriseBH: "",
      SFBH: "",
      SBH: "",
      XBH: "",
      type: 1,
      loading: false,

      parentTitle: "",
      title: "",
    };
  },

  components: {
    AppTree,
    BaseSelect,
  },

  mounted() {
    var me = this;
  },

  methods: {
    onChangeVal(e) {
      this.JZMC = e.target.value;
    },

    //查询
    onSearch() {
      this.current = 1;
      this.type = 1;
      this.EnterpriseBH = "";
      this.HJJC_STATIONLIST_DATA_ABNORMAL_LIST();
    },

    //导出
    onExport() {
      let me = this;
      // let ID = me.$store.state.loggedIn.CKM + ',,,,' + this.JZMC + ',,' + this.KSSJ + ',' + this.JZMC
      let ID = `${me.$store.state.loggedIn.CKM},${this.SFBH},${this.SBH},${this.XBH},${this.JZMC},,,,${this.EnterpriseBH}`;
      console.log(ID);

      let HTTPURL = this.$store.state.HTTPURL;
      // let HTTPURL = "http://www.warpsoft.cn:7186";
      window.open(
        "" +
          HTTPURL +
          "/data/RPT_GetPrintExcelFile.aspx?Module=SP&UID=admin&PID=&Template=104C4B0D23F6410B91A276HJJCSJYCLB&ID=" +
          ID +
          ""
      );
    },

    //树点击
    onSelect(item, parent, state) {
      console.log(item);
      console.log(parent);

      if (state == 1) {
      } else {
        this.current = 1;
        this.type = 2;
        this.parentTitle = this.$store.state.parentData.name;
        this.title = this.$store.state.selectedData.name;

        if (item.Lx == 5) {
          this.SFBH = "";
          this.SBH = "";
          this.XBH = "";
          this.XBH = "";
          this.JZMC = "";
          // this.JZMC = item.name;
          this.EnterpriseBH = item.id;
        } else if (item.Lx == 4) {
          //县
          this.SFBH = "";
          this.SBH = "";
          this.XBH = item.id;
          this.JZMC = "";
          this.EnterpriseBH = "";
        } else if (item.Lx == 3) {
          //市
          this.SFBH = "";
          this.SBH = item.id;
          this.XBH = "";
          this.JZMC = "";
          this.EnterpriseBH = "";
        } else if (item.Lx == 2) {
          //省
          this.SFBH = item.id;
          this.SBH = "";
          this.XBH = "";
          this.JZMC = "";
          this.EnterpriseBH = "";
        } else if (item.Lx == 1) {
          //全国
          this.SFBH = "";
          this.SBH = "";
          this.XBH = "";
          this.JZMC = "";
          this.EnterpriseBH = "";
        }

        this.HJJC_STATIONLIST_DATA_ABNORMAL_LIST();
      }
    },

    //分页
    onChange(pageNumber) {
      this.current = pageNumber;
      this.HJJC_STATIONLIST_DATA_ABNORMAL_LIST();
    },

    HJJC_STATIONLIST_DATA_ABNORMAL_LIST() {
      var me = this;
      // var data = {};
      // if (this.type == 1) {
      // 	data = {
      // 		CKM: me.$store.state.loggedIn.CKM,
      // 		SFBH: '',
      // 		SBH: '',
      // 		XBH: '',
      // 		JZMC: this.JZMC,
      // 		SXZM: '',
      // 		KSSJ: '',
      // 		JSSJ: '',
      // 		EnterpriseBH: '',
      // 		start: (me.current - 1) * me.pageSize,
      // 		limit: me.pageSize
      // 	}
      // } else if (this.type == 2) {
      // 	data = {
      // 		CKM: me.$store.state.loggedIn.CKM,
      // 		SFBH: '',
      // 		SBH: '',
      // 		XBH: '',
      // 		JZMC: '',
      // 		SXZM: '',
      // 		KSSJ: '',
      // 		JSSJ: '',
      // 		EnterpriseBH: this.EnterpriseBH,
      // 		start: (me.current - 1) * me.pageSize,
      // 		limit: me.pageSize
      // 	}
      // }
      me.loading = true;
      http({
        url: "/data/q30/HJJC_STATIONLIST_DATA_ABNORMAL_LIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          SFBH: this.SFBH,
          SBH: this.SBH,
          XBH: this.XBH,
          JZMC: this.JZMC,
          SXZM: "",
          KSSJ: "",
          JSSJ: "",
          EnterpriseBH: this.EnterpriseBH,
          start: (me.current - 1) * me.pageSize,
          limit: me.pageSize,
        },
        success(obj) {
          console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            for (var i = 0; i < obj.Data.length; i++) {
              obj.Data[i].TOTAL =
                obj.Data[i].Abnormal_num +
                obj.Data[i].Overproof_num +
                obj.Data[i].Early_num;
            }
          }

          me.data = obj.Data;
          me.loading = false;

          if (obj.Data1 && obj.Data1.length > 0) {
            me.total = obj.Data1[0].ROWSCOUNT;
          }
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },

    // methods中定义方法
    customRow(record, index) {
      return {
        on: {
          // 鼠标单击行
          click: (event) => {
            console.log(record);
            var me = this;

            //树数据
            var treeOriginalData = this.$store.state.treeOriginalData;
            // console.log(treeOriginalData);

            let selectData = null;
            for (var i = 0; i < treeOriginalData.length; i++) {
              if (treeOriginalData[i].id == record.station_bh) {
                // console.log(treeOriginalData[i]);
                me.$store.commit("setExpandedKeys", [
                  treeOriginalData[i].parentId,
                ]); //展开指定的树节点
                me.$store.commit("setSelectedKeys", [treeOriginalData[i].id]); //设置选中的树节点
                me.$store.commit("setSelectedData", treeOriginalData[i]); //选中的树节点数据对象
                selectData = treeOriginalData[i];
                break;
              }
            }

            if (selectData) {
              let parentData = treeOriginalData.filter(
                (it) => it.id == selectData.parentId
              );

              if (parentData && parentData.length > 0) {
                me.$store.commit("setParentData", parentData[0]); //选中的树节点数据对象
              }

              setTimeout(function () {
                me.$router.push("/main/alarm/1");
              }, 200);
            } else {
              this.$message.info("没有在树中找到对应的基站");
            }

            // console.log(me.$store.state.expandedKeys);
            // console.log(me.$store.state.selectedKeys);
            // console.log(me.$store.state.selectedData);
            // console.log(me.$store.state.parentData);
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#abnormal-base {
  font-family: PingFangSC-Medium, PingFang SC;
  height: 100%;

  .health {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    height: 100%;

    .content {
      flex: 1;
      width: 0;
      background-color: #ffffff;
      padding: 20px 20px 0;

      h3 {
        text-align: center;
        font-size: 18px;
        height: 25px;
        line-height: 25px;
        margin-bottom: 24px;
      }

      .search-shell {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }

      .search-btn {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .select-all {
          display: flex;
          align-items: center;
          margin-right: 20px;

          .label {
            width: 112px;
            font-size: 14px;
            color: #3f3f3f;
            line-height: 20px;
          }
        }

        .abnormal-btn {
          color: #ffffff;
          // font-size: 16px;
          background-color: #1876ff;
          // width: 72px;
          margin-right: 20px;
          // line-height: 30px;
        }
      }

      .table-shell {
        width: 100%;
        height: calc(100% - 145px);
        display: flex;
        flex-direction: column;

        @import "../../assets/css/AppHome.scss";

        &::v-deep .ant-table-thead {
          background: #ffffff;
        }

        .ant-table-wrapper {
          width: 100%;
          margin-bottom: 10px;
          flex: 1;
        }
      }
    }
  }
}
</style>
